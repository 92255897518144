@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'animation.css';
@import 'colors.css';

.nav-item:hover {
  @apply bg-primaryBlack-400 white-svg;
}

.nav-item--active {
  @apply bg-primaryBlack-400 white-svg;
}
html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

body {
  overflow-y: overlay;
  overflow-anchor: none;
}

a {
  color: inherit;
  text-decoration: none;
}

/*Tailwind class not working on prod. Temporary fix */
.max-w-px-485 {
  max-width: 485px;
}

* {
  box-sizing: border-box;
}
*,
*:focus,
*:hover {
  outline: none;
}

.text-fill-transparent {
  -webkit-text-fill-color: transparent;
}

.white-svg path,
.white-svg circle {
  fill: #fff;
}

/* Tailwind classes are not quite working with current svg import.
TODO: add color props support on our svg in common package. */

.hover-black-svg:hover path {
  fill: #000000;
}

.hover-purple-svg:hover path {
  fill: #725098;
}
.white-stroke-svg path {
  stroke: #fff;
}

.line-chart canvas {
  width: 100% !important;
}

/* Bg is changed via tailwind. Child elements are styled here */
.token-info-btn:hover svg {
  fill: #fff;
}
.token-info-btn:hover p {
  color: #fff;
}

/* Bullets with line for person page Experience Item */
ul.experiences li {
  position: relative;
  margin-bottom: 0;
  padding-bottom: 1em;
}

ul.experiences li:after {
  /* bullets */
  content: '';
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background: #5e5a67;
  position: absolute;
  left: -48px; /*adjust manually*/
  top: 10px;
}

ul.experiences li:before {
  /* lines */
  content: '';
  position: absolute;
  left: -45px; /* adjust manually */
  border-left: 2px solid #5e5a67;
  height: 80%;
  width: 3px;
  top: 21px;
}

ul.experiences li:first-child:before {
  /* first li's line */
  top: 21px; /* moves the line down so that it disappears under the bullet. Adjust manually */
}

ul.experiences li:last-child:before {
  /* last li's line */
  height: 0px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* Next js Image component cant set dynamic height or width. It always require image of fix size.
 Workaround for that from its github thread https://github.com/vercel/next.js/discussions/18739
*/
.auto-width-image-container {
  position: relative;
}
.auto-width-image-container > span {
  position: static !important;
  height: 100% !important;
}

.auto-width-image {
  object-fit: contain;
  width: 100% !important;
  position: static !important;
  height: unset !important;
}
